<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                @click="pay()"
                type="success"
                :loading="loadingButton"
                :disabled="
                  (loadingButton ? true : false) || form.invoice.is_paid
                "
                >{{ $t("message.pay") }}</el-button
              >

              <el-button
                size="mini"
                @click="printExcel"
                icon="el-icon-document-delete"
              >
                Excel
              </el-button>

              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="modal-doctoe-info" v-loading="loadingData">
      <el-row :gutter="10" class="credit_row">
        <el-col :span="6">
          <div class="font__W">{{ $t("message.clinic") }}</div>
          <div>
            {{ form.invoice.partner_clinic ? form.invoice.partner_clinic.name : "" }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>
            {{ form.invoice.bonuses.length }}
          </div>
        </el-col>

        <el-col :span="6">
          <div class="font__W">{{ $t("message.total_amount") }}</div>
          <div>
            {{ total_prices | formatMoney }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.status") }}</div>
          <div>
            {{ form.is_paid ? $t("message.paid") : $t("message.not_paid") }}
          </div>
        </el-col>
      </el-row>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('message.order')" name="first"
          ><div class="text-center float-left sorddata my-3">
            <!-- <el-date-picker
          size="mini"
          v-model="filterForm.orders_from"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_to"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker> -->
          </div>

          <table
            class="table table-hover table-bordered mt-3 table__cerditne__forma"
          >
            <thead>
              <tr>
                <th>id</th>
                <th scope="col">{{ $t("message.ifo") }}</th>
                <th scope="col">{{ $t("message.service") }}</th>
                <th scope="col">{{ $t("message.order_date") }}</th>
                <th scope="col">{{ $t("message.conclusion_date") }}</th>
                <th scope="col">{{ $t("message.score") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in form.invoice.bonuses"
                :key="index"
                :style="colorPicker(form.invoice)"
              >
                <td>{{ item.order_id }}</td>
                <td>
                  {{
                    item.order && item.order.patient
                      ? item.order.patient.surname +
                        " " +
                        item.order.patient.first_name
                      : ""
                  }}
                </td>
                <td
                  class="service"
                  v-if="item.order && item.order.order_services_service"
                >
                  <span
                    v-for="(service, index) in item.order
                      .order_services_service"
                    :key="index"
                  >
                    {{ service.name ,}}
                  </span>
                </td>

                <td v-else>
                  {{ " - " }}
                </td>

                <td>{{ item.order ? item.order.created_at : "" }}</td>
                <td>
                  {{
                    item.order
                      ? item.order.conculations.length
                        ? item.order.conculations[0].created_at
                        : ""
                      : ""
                  }}
                </td>
                <td>
                  <span class="act_color">{{
                    item.paid_amount | formatMoney
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table></el-tab-pane
        >
        <el-tab-pane :label="$t('message.service')" name="second">
          <table
            class="table table-hover table-bordered mt-3 table__cerditne__forma"
          >
            <thead>
              <tr>
                <th>id</th>
                <th scope="col">{{ $t("message.service") }}</th>
                <th scope="col">{{ $t("message.count") }}</th>
                <th scope="col">{{ $t("message.total_amount") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in form.services" :key="index">
                <td>{{ index }}</td>
                <td class="service">
                  {{ item.length ? item[0].name : "" }}
                </td>
                <td>{{ item.length }}</td>
                <td>{{ item.reduce((a, b) => a + (+b.price || 0), 0) }}</td>
              </tr>
            </tbody>
          </table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, drawerChild],
  props: ["selected"],
  data() {
    return {
      activeName: "first",
      payment_type_id: null,
      loadingButton: false,
      checkedOrders: [],
      dialogVisible: false,
      filterForm: {
        orders_from: "",
        orders_to: "",
      },
      loadingData: false,
      excel_data: [],

      excel_fields: {},
    };
  },
  computed: {
    ...mapGetters({
      model: "invoice/model",
    }),
    total_prices: function () {
      let bonuses = this.form.invoice.bonuses;
      let prices = 0;
      for (let i = 0; i < bonuses.length; i++) {
        const element = bonuses[i];
        prices += parseFloat(element.paid_amount);
      }
      return prices;
    },
  },

  watch: {
    filterForm: {
      handler: function (newVal, oldVal) {
        // this.showModel();
      },
      deep: true,
      immediate: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    this.controlExcelData();
  },
  methods: {
    ...mapActions({
      showModel: "invoice/show",
      excelReport: "invoice/excel",
      payInvoice: "invoice/pay",
    }),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    colorPicker(form) {
      if (form.is_paid) {
        return "background-color: rgb(171 255 168 / 62%)";
      } else {
        return "background-color: #ffffff";
      }
    },

    addPayment(item, event) {
      if (event == true) {
        this.checkedOrders.push(item);
      }
      if (event == false) {
        const index = this.checkedOrders.indexOf(item);
        if (index > -1) {
          this.checkedOrders.splice(index, 1);
        }
      }
    },
    pay() {
      this.loadingButton = true;
      this.loadingData = true;
      this.payInvoice(this.form.invoice)
        .then((res) => {
          this.showModel(this.form.invoice.id);
          this.loadingData = false;
          this.parent().listChanged();
          this.loadingButton = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadingButton = false;
        });
    },
    afterOpened() {},
    afterClosed() {
      this.$emit("c-close", { drawer: "componentDrawerShow" });
    },

    printExcel() {
      this.excelReport({ id: this.form.invoice.id })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Отчеты счет-фактур.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    controlExcelData() {
      this.excel_fields = {
        id: "order_id",

        "И.Ф.О": {
          field: "order",
          callback: (value) => {
            return value.patient.surname + " " + value.patient.first_name;
          },
        },
        Услуга: {
          field: "order",
          callback: (value) => {
            let array = [];
            if (value.order_services_service.length) {
              value.order_services_service.forEach((value, index) => {
                if (!array.includes(value.name)) {
                  array.push(value.name);
                }
              });
            }
            return array.toString();
          },
        },
        "Родительские Услуга": {
          field: "order",
          callback: (value) => {
            let array = [];
            if (value.order_services_service.length) {
              value.order_services_service.forEach((value, index) => {
                if (!array.includes(value.parent.name)) {
                  array.push(value.parent.name);
                }
              });
            }
            return array.toString();
          },
        },
        "Дата заказа": {
          field: "order",
          callback: (value) => {
            return value.created_at;
          },
        },
        "Дата заключения": {
          field: "order",
          callback: (value) => {
            return value.conculations[0].created_at;
          },
        },
        // Сумма: {
        //   field: "order",
        //   callback: (value) => {
        //     return value.total_price;
        //   },
        // },
        Счета: "paid_amount",
      };
    },
    resetForm() {
      this.$emit("c-close", { drawer: "componentDrawerShow" });
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 14px;
  }
}
.service {
  width: 350px;
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #303030 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>